import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Services from '../components/services'
import FeaturedPosts from '../components/featured-posts'

const IndexPage = () => {
  const { site } = useStaticQuery(
    graphql`
      {
        site: configPagesRedfinSettings {
          field_home_title
          field_home_tagline
          field_about_text
          field_home_image {
            alt
          }
          relationships {
            field_home_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
            field_cards {
              field_text
              field_title
            }
            field_featured_blog {
              title
              path {
                alias
              }
              body {
                summary
              }
              field_image {
                alt
              }
              created
              relationships {
                uid {
                  name
                }
                field_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 650, height: 385, layout: CONSTRAINED)
                    }
                  }
                }
              }
            }
            field_featured_portfolio {
              title
              path {
                alias
              }
              body {
                summary
              }
              field_image {
                alt
              }
              created
              relationships {
                field_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 650, height: 385, layout: CONSTRAINED)
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const homeImage = getImage(site?.relationships?.field_home_image?.localFile)
  const homeImageAlt = site?.field_home_image?.alt || ''
  const blogNode = site?.relationships?.field_featured_blog
  const portfolioNode = site?.relationships?.field_featured_portfolio
  return (
    <>
      <SEO title="Home" />
      <Hero
        title={site?.field_home_title}
        subtitle={site?.field_home_tagline}
        image={homeImage}
        alt={homeImageAlt}
        link="/portfolio"
        linkText="Our work"
      />
      <Services services={site?.relationships?.field_cards} />
      <FeaturedPosts blogNode={blogNode} portfolioNode={portfolioNode} />
    </>
  )
}

export default IndexPage
