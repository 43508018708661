import React from 'react'
import Button from './button'
import Teaser from './teaser'
import * as featuredStyles from '../styles/modules/featured.module.scss'

const FeaturedPosts = ({ blogNode, portfolioNode }) => (
  <section className={featuredStyles.featured}>
    {blogNode ? (
      <div>
        <div className={featuredStyles.intro}>
          <h2>From Our Blog</h2>
          <Button link="/blog" text="Blog" marginNone medium scale />
        </div>
        <Teaser
          single
          alias={blogNode.path.alias}
          image={
            blogNode.relationships.field_image.localFile.childImageSharp.gatsbyImageData
          }
          alt={blogNode.field_image.alt}
          title={blogNode.title}
          author={blogNode.relationships.uid.name}
          created={blogNode.created}
          summary={blogNode.body.summary}
        />
      </div>
    ) : (
      ''
    )}
    {portfolioNode ? (
      <div>
        <div className={featuredStyles.intro}>
          <h2>Featured Portfolio</h2>
          <Button link="/portfolio" text="Portfolio" marginNone medium scale />
        </div>
        <Teaser
          single
          alias={portfolioNode.path.alias}
          image={
            portfolioNode.relationships.field_image.localFile.childImageSharp
              .gatsbyImageData
          }
          alt={portfolioNode.field_image.alt}
          title={portfolioNode.title}
          created={portfolioNode.created}
          summary={portfolioNode.body.summary}
        />
      </div>
    ) : (
      ''
    )}
  </section>
)

export default FeaturedPosts
