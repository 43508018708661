import React from 'react'
import Stripe from './stripe'
import * as cardStyles from '../styles/modules/card.module.scss'

const ServiceCard = ({ title, text }) => (
  <div className={cardStyles.card}>
    <Stripe firstColor="#326295" secondColor="#326295" />
    <div className={cardStyles.info}>
      <h3>{title}</h3>
      <p>{text}</p>
    </div>
  </div>
)

export default ServiceCard
