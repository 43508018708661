import React from 'react'
import Button from './button'
import ServiceCard from './service-card'
import * as servicesStyles from '../styles/modules/services.module.scss'

const Services = ({ services }) => (
  <>
    <section className="contentContainer">
      <div className={servicesStyles.intro}>
        <h2>Our Services</h2>
        <div className={servicesStyles.details}>
          <div className={servicesStyles.text}>
            Together, we take your vision to the next level through proven strategy,{' '}
            design, development, and support. Rest easy knowing that your Redfin team will{' '}
            deliver a Drupal website that exceeds your expectations.
          </div>
          <Button link="/about" text="About us" marginNone big />
        </div>
      </div>
    </section>
    <section className={servicesStyles.grid}>
      {services.map((service, index) => (
        <ServiceCard title={service.field_title} text={service.field_text} key={index} />
      ))}
    </section>
  </>
)

export default Services
