import React from 'react'
// import BackgroundImage from 'gatsby-background-image'
import { BgImage } from 'gbimage-bridge'
import Button from './button'
import {
  heroContainer,
  heroGrid,
  heroInfo,
  heroTitle,
  heroSubtitle,
} from '../styles/modules/hero.module.scss'

const Hero = ({ title, subtitle, image, alt, link, linkText }) => {
  const backgroundImageStack = [
    image,
    `linear-gradient(rgba(50, 98, 149, 0.5),
    rgba(50, 98, 149, 0.5))`,
    `linear-gradient(180deg, rgba(0,0,0,1) 0%, transparent 50%)`,
  ].reverse()
  return (
    <section className={heroContainer}>
      <BgImage
        image={backgroundImageStack}
        loading="eager"
        backgroundColor="#322f31"
        title={alt || ''}
      >
        <div className={heroGrid}>
          <div className={heroInfo}>
            <h1 className={heroTitle}>{title}</h1>
            <h2 className={heroSubtitle}>{subtitle}</h2>
            <Button gradient link={link} text={linkText} big shadow />
          </div>
        </div>
      </BgImage>
    </section>
  )
}

export default Hero
